<template>
  <div class="layout-padding" v-if="order">
    <PageTitle class="my-8" :title="content.order + ' #' + format(order.id)" :childOne="content.account"
      :childTwo="content.orderHistory" />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8 mb-12">
        <div class="page-title mb-6">{{ content.items }}</div>
        <OrderItemCard v-for="item in order.products" :key="item.id" :item="item" />
        <RefillItemCard v-for="item in order.refills" :key="item.id" :item="item" />
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery :status="order.status" :date="order.date" :subTotal="order.total"
          :delivery="order.city?.price ?? 0" :total="total" @pay="payLater" :paid="order.paid">
          <div class="grid grid-cols-3 px-4 xl:px-10">
            <div class="col-span-1">{{ content.items }}</div>
            <div class="col-span-1 text-center">{{ content.qty }}</div>
            <div class="col-span-1 text-align-end">{{ content.price }}</div>
          </div>
          <div class="grid grid-cols-3 px-4 xl:px-10 mt-4" v-for="item in order.products" :key="item.id">
            <div class="col-span-1">{{ item.detail.title }}</div>
            <div class="col-span-1 text-center">
              {{ format(item.quantity) }}
            </div>
            <div class="col-span-1 text-align-end">
              {{
    item.size?.price ? format(item.size?.price) : format(item.detail.price)
  }}
              {{ content.iqd }}
            </div>
          </div>
          <div class="grid grid-cols-3 px-4 xl:px-10 mt-4" v-for="item in order.refills" :key="item.id">
            <div class="col-span-1">
              {{ content.refillRequest }}
              <br />
              <p class="text-sm mt-1 opacity-70">
                {{ item.container.title }} | {{ item.size?.title }} |
                {{ item.wick.title }} | {{ item.color.title }} | {{ item.scent.title }}
              </p>
            </div>
            <div class="col-span-1 text-center">
              {{ format(item.quantity) }}
            </div>
            <div class="col-span-1 text-align-end">
              {{ format(item.total) }}
              {{ content.iqd }}
            </div>
          </div>
          <hr style="color: #f2715b22" class="my-5 mx-10" />
        </OrderSummery>
        <OrderShipping :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderItemCard from "/src/components/ui/Order/OrderItemCard.vue";
import RefillItemCard from "/src/components/ui/Order/RefillItemCard.vue";
import OrderSummery from "/src/components/ui/Order/OrderSummery.vue";
import OrderShipping from "../../../components/ui/Order/OrderShipping.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: { OrderItemCard, RefillItemCard, OrderSummery, OrderShipping },
  props: ["id"],
  inject: ["content"],
  async mounted() {
    if (this.auth) {
      await this.$store.dispatch("orders/orders");
    } else {
      await this.$store.dispatch("orders/guestOrder", { email: this.$route.query.email, uuid: this.id });
    }

    if (this.auth && !this.$store.getters["orders/order"](this.id)) {
      this.$router.replace("/account/orderHistory");
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    order() {
      if (this.user) {
        return this.$store.getters["orders/order"](this.id);
      }
      return this.$store.getters["orders/guestOrder"];
    },
    total() {
      return this.order.city
        ? parseFloat(this.order.total) + parseFloat(this.order.city.price)
        : this.order.total;
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
    async payLater(method) {
      try {
        this.$store.dispatch("paylater/pay", {
          id: this.id,
          type: "order",
          method: method,
        });
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.errorOccured,
        });
      }
    },
  },
};
</script>
