<template>
  <div class="order-item-card mb-5 smd:flex-row flex-col flex items-center">
    <div
      :style="`background: url(${item.detail.image})`"
      class="order-item-img"
    />
    <div class="px-6 flex justify-between md:px-8 w-full mb-8 smd:mb-0">
      <div>
        <div class="item-title lora-bold">{{ item.detail.title }}</div>
        <div
          class="item-category mt-2 lora-italic"
          v-if="item.detail.product.collection"
        >
          {{ item.detail.product.collection.title }}
        </div>
        <div class="flex items-center mt-2">
          <div class="rating">
            <div
              class="rating-upper"
              :style="`width:calc( ( ${item.detail.product.rating} / 5 ) * 100%)`"
            >
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
            <div class="rating-lower">
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
              <span><i class="fas fa-star"></i></span>
            </div>
          </div>
          <div class="ms-4">
            {{ format(item.detail.product.ratings) }} {{ content.review_s }}
          </div>
        </div>
        <div class="smd:hidden flex mt-2">
          {{ content.quantity }}
          <div class="ms-4 lora-bold">{{ format(item.quantity) }}</div>
        </div>
        <div class="item-category text-size-20 lora-bold mt-4">
          {{ item.detail.price ? format(item.detail?.price) : format(item.size?.price) }} {{ content.iqd }}
        </div>
      </div>
      <div class="hidden items-center smd:flex me-6">
        {{ content.quantity }}
        <div class="ms-4 lora-bold">{{ format(item.quantity) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  inject: ["content"],
  props: ["item"],
  methods: {
    format(number) {
      return format(number);
    },
  },
};
</script>
